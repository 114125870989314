const trackEvent = (name, params = {}) => {
    console.log(`Tracking event '${name}' with params: `, params);
    gtag('event', name, params);
};

/**
 * Step1: User enters ZIP
 *
 * @param zip       Zip the user entered
 * @param region    region the user selected (null if no region was possible
 */
export const trackZipEntered = (zip, region = null) => {
    trackEvent('zip_entered', { zip, region });
};

/**
 * Step2: User Selects Building Type
 *
 * @param buildingType
 */
export const trackBuildingTypeSelect = (buildingType) => {
    trackEvent('buildingType_select', { buildingType });
};

/**
 * Step3: User clicks "calculate" and fetches a price
 *
 * @param amount
 */
export const trackCalculationSubmit = (amount) => {
    trackEvent('calculation_submit', { amount });
};

/**
 * Step4: User submits contact form
 */
export const trackContactFormSubmit = (name, email, phone, street, city) => {
    trackEvent('contactform_submit', { name, email, phone, street, city });
};
