'use strict';

import React, { Component } from 'react';
import classNames from 'classnames';

export const TYPE_EINFAMILIENHAUS = 'einfamilienhaus';
export const TYPE_MEHRFAMILENHAUS = 'mehrfamilienhaus';
export const TYPE_SCHEUNE = 'scheune';
export const TYPE_HALLE = 'halle';
export const TYPE_SONSTIGES = 'sonstiges';

const buildingTypes = {
    [TYPE_EINFAMILIENHAUS]: 'Einfamilienhaus',
    [TYPE_MEHRFAMILENHAUS]: 'Mehrfamilienhaus',
    [TYPE_SCHEUNE]: 'Scheune',
    [TYPE_HALLE]: 'Halle',
    [TYPE_SONSTIGES]: 'Fabriken, Büros, etc.',
};

const images = {
    [TYPE_EINFAMILIENHAUS]: ['einfamilienhaus.png', 'einfamilienhaus-weiss.png', 'einfamilienhaus-weiss-o.png'],
    [TYPE_MEHRFAMILENHAUS]: ['mehrfamilienhaus.png', 'mehrfamilienhaus-weiss.png', 'mehrfamilienhaus-weiss-o.png'],
    [TYPE_SCHEUNE]: ['scheune.png', 'scheune-weiss.png', 'scheune-weiss.png'],
    [TYPE_HALLE]: ['halle.png', 'halle-weiss.png', 'halle-weiss-o.png'],
    [TYPE_SONSTIGES]: ['industrie-buero-frage.png', 'industrie-buero-frage-weiss.png', 'industrie-buero-frage-weiss.png'],
};

const getImage = (type, hover) => `/images/${images[type][hover ? 2 : 1]}`;

class BuildingTypeSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hover: ''
        }
    }
    /**
     * @returns {Array}
     */
    getBuildingTypes() {
        const { buildingType, setBuildingType } = this.props;

        return Object.keys(buildingTypes).map(key => {
            return {
                key,
                name: buildingTypes[key],
                active: buildingType === key,
                hover: this.state.hover === key,
                set: () => setBuildingType(key),
                enter: () => this.setState({hover: key}),
                leave: () => this.setState({hover: ''})
            }
        });
    }

    /**
     * @param buildingType
     * @returns {boolean}
     */
    isBuildingType(buildingType) {
        return this.props.buildingType === buildingType;
    }

    render() {
        return (
            <div>
                <div className="d-block d-sm-none">
                    <div className="list-group my-2">
                        {this.getBuildingTypes().map(buildingType => (
                            <button type="button" key={buildingType.key} name={name}
                                className={classNames('list-group-item list-group-item-action', { active: buildingType.active })}
                                onClick={() => buildingType.set()}>
                                {buildingType.name}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="d-none d-sm-block">
                    <div className="row no-gutters bg-light">
                        {this.getBuildingTypes().map(buildingType => (
                            <div key={buildingType.key} className="col-6 col-sm-4 col-md text-center">
                                <a onClick={() => buildingType.set()} onMouseEnter={() => buildingType.enter()} onMouseLeave={() => buildingType.leave()} className={classNames('btn p-4', { [buildingType.active ? 'btn-primary' : 'btn-light']: true })} style={{ width: '100%' }}>
                                    <img src={getImage(buildingType.key, buildingType.hover)} className="img-fluid" style={{ maxWidth: '80px' }} /><br />
                                    <small>{buildingType.name}</small>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>

                {this.isBuildingType(TYPE_SONSTIGES) && <div className="alert alert-warning mt-2">
                    <p>Für Bürogebäude, Industrieabbrüche oder Abbrüche die hier nicht berechnet werden können, bitten wir Sie direkt
                        Kontakt mit unserer Kalkulationsabteilung aufzunehmen:</p>
                    <p>
                        E-Mail: <a href="mailto:info@ser-gmbh.net">info@ser-gmbh.net</a><br />
                        Telefon: 07131/59490-0</p>
                    <p className="mb-0">Vielen Dank!</p>
                </div>}
            </div>
        );
    }
}
BuildingTypeSelection.defaultProps = {
    buildingType: '',
    setBuildingType: (buildingType) => { },
};

export default BuildingTypeSelection;
