'use strict';

import numeral from 'numeral';
import 'numeral/locales/de';
numeral.locale('de');

/**
 * Parses a number from a string
 *
 * @param number
 * @returns {number | null}
 */
export const parseNumber = number => parseFloat((number+'').replace(/,/, '.').replace(/\s+/, '')) || null;

/**
 * Formats a number with one decimal point
 *
 * @param number
 * @returns {*}
 */
export const formatNumber = number => numeral(number).format('0,0.0');

/**
 * Formats a number with one decimal point but with different behavior on 0
 *
 * @param number
 * @param nullValue
 * @returns {*}
 */
export const formatNumberExceptNull = (number, nullValue = '') => {
    if (null === number) {
        return nullValue;
    }
    return formatNumber(number);
};

/**
 * Formats a price with currency
 *
 * @param price
 * @param currency
 * @returns {string}
 */
export const formatPrice = (price, currency = 'EUR') => `${numeral(price).format('0,0.00')} ${currency}`;
