'use strict';

import React, { Component } from 'react'
import * as api from './api';
import * as analytics from './analytics';
import queryString from 'query-string';

import BuildingTypeSelection, { TYPE_EINFAMILIENHAUS, TYPE_MEHRFAMILENHAUS, TYPE_SCHEUNE, TYPE_HALLE, TYPE_SONSTIGES } from "./components/BuildingTypeSelection";
import ContactForm from './components/ContactForm'
import Price from './components/Price';
import CalculationForm from "./components/CalculationForm";

const EMPTY_STATE = {
    buildingType: '',
    zip: '',
    uR: null,
    stall: null,
    dachBlech: null,
    dachPappe: null,
    dachBims: null,
    wandBlech: null,
    wandGlas: null,
    schadstoffe: []
};

const STEP_CALCULATION = 'calculation';
const STEP_RESULT = 'result';
const STEP_FINAL = 'final';

export default class Application extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: STEP_CALCULATION,
            zipComplete: false,
            zipValid: false,
            zipEntered: '',
            region: '',
            price: null,
            canCalculate: false,
            calculation: EMPTY_STATE,
        };
    }

    componentDidMount() {
        // focus input when "focus" parameter is passed
        if (queryString.parse(window.location.search).focus) {
            this.zipSelector.focus();
        }
    }

    setCalculation(key, value) {
        const { calculation } = this.state;

        calculation[key] = value;

        if ('buildingType' === key) {
            analytics.trackBuildingTypeSelect(value);
        }

        this.setState({
            calculation,
            // always reset price when calculation changes
            price: null
        });
    }

    isBuildingType(buildingType) {
        return this.state.calculation.buildingType === buildingType;
    }

    checkZip(zip) {
        this.setState({ zipEntered: zip });

        // zip entered is wrong
        if (null === zip.match(/^\d{5}$/)) {
            this.setState({
                zipComplete: false,
                zipValid: false,
                region: '',
                calculation: {
                    ...this.state.calculation,
                    zip: ''
                }
            });
            return;
        }

        api.checkZip(zip, json => {
            analytics.trackZipEntered(zip, json.region);
            this.setState({
                zipComplete: true,
                zipValid: json.valid,
                region: json.region,
                calculation: {
                    ...EMPTY_STATE,
                    zip: json.zip
                }
            });
        });
    }

    calculate() {
        const { calculation } = this.state;
        api.calculate(calculation, json => {
            analytics.trackCalculationSubmit(json.price);
            this.setState({
                step: STEP_RESULT,
                price: json.price,
            });
        });
    }

    sendContactForm(contact, files) {
        const { calculation } = this.state;

        api.sendContactForm(calculation, contact, files, json => {
            analytics.trackContactFormSubmit(contact.name, contact.email, contact.phone, contact.street, contact.city);
            this.setState({
                step: STEP_FINAL
            });
        });
    }

    move(step) {
        this.setState({ step });
    }

    render() {
        const { step, zipComplete, zipValid, price } = this.state;

        switch (step) {
            case STEP_CALCULATION:
                return (
                    <div>
                        {this.renderZip()}

                        <BuildingTypeSelection buildingType={this.state.calculation.buildingType} setBuildingType={(buildingType) => this.setCalculation('buildingType', buildingType)} />

                        {zipComplete && zipValid && this.renderCalculation()}

                        {!zipComplete && <div className="alert alert-warning mt-2">
                            Bitte geben Sie im PLZ Feld oben Ihre PLZ ein.
                        </div>}
                    </div>
                );
            case STEP_RESULT:
                return (
                    <div>
                        <Price price={price} />
                        <ContactForm submit={(contact, files) => this.sendContactForm(contact, files)} back={() => this.move(STEP_CALCULATION)} />
                    </div>
                );
            case STEP_FINAL:
                return <div>Vielen Dank für Ihre Anfrage. Wir werden uns bald bei Ihnen melden.</div>;
        }
    }

    renderZip() {
        const { zipComplete, zipValid, region } = this.state;

        const ComponentZipInvalid = () => (
            <div className="alert alert-danger mt-2">
                <p>Leider befindet sich das Gebäude außerhalb unseres Einzugsgebietes. Daher ist es uns nicht möglich Ihnen
                    maschinell eine Kostenschätzung zur Verfügung zu stellen.</p>
                <p>Sollten Sie dennoch ein Angebot wünschen, setzen Sie sich bitte direkt mit unserer
                    Kalkulationsabteilung in Verbindung:</p>
                <p>E-Mail: <a href="mailto:info@ser-gmbh.net">info@ser-gmbh.net</a><br />
                    Telefon: 07131/59490-0</p>
                <p className="mb-0">Vielen Dank.</p>
            </div>
        );

        return (
            <div className="zip-selector">
                <input type="text" id="zip-selector"
                       className="form-control form-control-lg text-sm-center"
                       name="plz"
                       placeholder="Bitte geben Sie hier Ihre PLZ ein"
                       ref={e => this.zipSelector = e}
                       maxLength={5} onChange={(e) => this.checkZip(e.target.value)} value={this.state.zipEntered} />
                {region && 
                    <small className="text-muted region">Region {region}</small>
                }
                {zipComplete && !zipValid && 
                    <ComponentZipInvalid />
                }
            </div>
        );
    }



    renderCalculation() {
        const { calculation } = this.state;

        if (!calculation.buildingType || this.isBuildingType(TYPE_SONSTIGES)) {
            return;
        }

        const updateSchadstoffe = schadstoffe => this.setState({
            calculation: {
                ...this.state.calculation,
                schadstoffe
            }
        });

        return <CalculationForm calculation={calculation}
            setCalculation={(key, value) => this.setCalculation(key, value)}
            updateSchadstoffe={(schadstoffe) => updateSchadstoffe(schadstoffe)}
            calculate={() => this.calculate()} />;
    }
}
