'use strict';

import React, { Component } from 'react';

let fileCnt = 0;

// react basic form
// https://codepen.io/jmalfatto/pen/YGjmaJ

class ContactForm extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            name    : '',
            email   : '',
            phone   : '',
            street  : '',
            city    : '',
            yesno   : true,
            street2 : '',
            city2   : '',
            files   : [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });

        this.showInputError(e.target.name);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.showFormErrors()) {
            this.props.submit(this.state, this.state.files.map(fileFieldName => this.refs[fileFieldName]));
        }
    }

    showFormErrors() {
        // needed because of stupid IE: https://davidwalsh.name/nodelist-array
        const inputs = [].slice.call(document.querySelectorAll('input.handle'));
        let isFormValid = true;

        inputs.forEach(input => {
            const isInputValid = this.showInputError(input.name);
            if (!isInputValid) {
                input.classList.add('is-invalid');
                isFormValid = false;
            } else {
                input.classList.remove('is-invalid');
            }
        });

        return isFormValid;
    }

    showInputError(refName) {
        const validity = this.refs[refName].validity;
        const label = document.getElementById(`${refName}Label`).textContent;
        const error = document.getElementById(`${refName}Error`);

        // agb comes with its own texts
        if ('agb' === refName) {
            return validity.valid;
        }

        if (!validity.valid) {
            if (validity.valueMissing) {
                error.textContent = `${label} ist ein Pflichtfeld`;
            } else if (validity.typeMismatch) {
                error.textContent = `${label} muss eine gültige E-Mail Adresse sein`;
            }
            return false;
        }

        error.textContent = '';
        return true;
    }


    addFile(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({files: [...this.state.files, `file_${fileCnt++}`]});
    }

    removeFile(i) {
        const { files } = this.state;
        files.splice(i, 1);
        this.setState({ files });
    }

    render() {
        return (
            <form noValidate>
                <div className="card card-body mb-1">
                <h2>ANGEBOT ANFORDERN</h2>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label id="nameLabel">Name</label>
                                <input className="form-control handle"
                                       type="text"
                                       name="name"
                                       ref="name"
                                       value={ this.state.name }
                                       onChange={ this.handleChange }
                                       pattern=".{5,}"
                                       required />
                                <div className="invalid-feedback" id="nameError" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label id="emailLabel">E-Mail</label>
                                <input className="form-control handle"
                                       type="email"
                                       name="email"
                                       ref="email"
                                       value={ this.state.email }
                                       onChange={ this.handleChange }
                                       pattern=".{5,}"
                                       required />
                                <div className="invalid-feedback" id="emailError" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label id="phoneLabel">Telefon</label>
                                <input className="form-control handle"
                                       type="phone"
                                       name="phone"
                                       ref="phone"
                                       value={ this.state.phone }
                                       onChange={ this.handleChange }
                                       pattern=".{5,}"
                                       required />
                                <div className="invalid-feedback" id="phoneError" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label id="streetLabel"> Straße/Hausnummer</label>
                                <input className="form-control handle"
                                       type="street"
                                       name="street"
                                       ref="street"
                                       value={ this.state.street }
                                       onChange={ this.handleChange }
                                       pattern=".{5,}"
                                       required />
                                <div className="invalid-feedback" id="streetError" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label id="cityLabel"> PLZ/Ort</label>
                                <input className="form-control handle"
                                       type="city"
                                       name="city"
                                       ref="city"
                                       value={ this.state.city }
                                       onChange={ this.handleChange }
                                       pattern=".{5,}"
                                       required />
                                <div className="invalid-feedback" id="cityError" />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group mb-3">
                                <label>Kontaktadresse entspricht der Adresse des Abrissobjektes?</label>
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="isAbriss" value="yes" checked={this.state.yesno} onClick={() => this.setState({yesno: true})} />
                                        Ja
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="isAbriss" value="no" checked={!this.state.yesno} onClick={() => this.setState({yesno: false})} />
                                        Nein
                                    </label>
                                </div>
                            </div>
                            {!this.state.yesno &&
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label id="street2Label"> Straße/Hausnummer des Abrissobjektes</label>
                                            <input className="form-control handle"
                                                   type="street"
                                                   name="street2"
                                                   ref="street2"
                                                   value={ this.state.street2 }
                                                   onChange={ this.handleChange }
                                                   pattern=".{5,}"
                                                   required />
                                            <div className="invalid-feedback" id="street2Error" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label id="city2Label"> PLZ/Ort des Abrissobjektes</label>
                                            <input className="form-control handle"
                                                   type="city"
                                                   name="city2"
                                                   ref="city2"
                                                   value={ this.state.city2 }
                                                   onChange={ this.handleChange }
                                                   pattern=".{5,}"
                                                   required />
                                            <div className="invalid-feedback" id="city2Error" />
                                        </div>
                                    </div>
                                </div>}
                            <hr/>
                        </div>
                        <div className="col-sm-12">
                        {0 === this.state.files.length && <p className="mb-0 mt-2">Sie können beliebige Dateien wie Grundrisspläne o.ä. hinzufügen. Klicken Sie dazu bitte auf "Datei hinzufügen".</p>}
                        {this.state.files.map((fileFieldName, i) => (
                            <div className="form-group" key={i}>
                                <button type="button" className="close align-middle pull-right mt-3" aria-label="Close" onClick={() => this.removeFile(i)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                <label id={`${fileFieldName}Label`} >Datei {i+1}</label>
                                <input className="form-control" type="file" name={fileFieldName} ref={fileFieldName} onChange={ this.handleChange } style={{width:'90%'}} />
                                <div className="small">Maximale Dateigröße: 10 MB</div>
                                <div className="invalid-feedback" id={`${fileFieldName}Error`} />
                            </div>
                        ))}
                        </div>
                        <div className="col-sm-12">
                            <hr/>

                            <div className="form-check">
                                <input type="checkbox" className="handle" name="agb" id="agbCheck" required ref="agb" value={ this.state.agb } onChange={ this.handleChange } />
                                <label className="mb-0 ml-1" htmlFor="agbCheck" id="agbLabel">Ja, ich habe die <a href="https://ser-gmbh.net/datenschutzrichtlinien/" target="_blank">Datenschutzerklärung</a> sowie die <a href="https://ser-gmbh.net/wp-content/uploads/2019/03/Einwilligungserkl%C3%A4rung-SER-GmbH.pdf" target="_blank">Einverständniserklärung</a> von SER gelesen und stimme diesen zu.</label>
                                <div className="invalid-feedback" id="agbError">Bitte stimmen Sie der Datenschutzerklärung und der Einverständniserklärung zu.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-sm-6">
                        <button className="btn btn-secondary" onClick={() => this.props.back()}>Zurück</button>
                    </div>
                    <div className="col-sm-6 text-right">
                        <button className="btn btn-secondary" onClick={(e) => this.addFile(e)}>Datei hinzufügen</button>
                        {' '}
                        <button type="submit" className="btn btn-primary" onClick={ this.handleSubmit }>Unverbindliches Angebot anfordern</button>
                    </div>
                </div>
            </form>
        );
    }
}
ContactForm.defaultProps = {
    back:   () => {},
    submit: (contact, file) => {},
};

export default ContactForm;
