'use strict';

import React, { Component } from 'react';
import classNames from 'classnames';

import {parseNumber, formatNumber, formatNumberExceptNull} from "./number"

class InputDimensions extends Component
{
    constructor(props) {
        super(props);
        this.myRefs = {};

        this.updateValue = this.updateValue.bind(this);
    }

    /**
     * Fetches all dimensions needed to render
     * @returns {Array}
     */
    getDimensions() {
        const { dimensions } = this.props;

        return Object
            .keys(dimensions)
            .map(key => ({
                key,
                name: dimensions[key]
            }));
    }

    /**
     * Called when one of the basic values
     */
    updateFragment(field, blur = false) {
        let result = {};
        let dimensions = 1;

        let complete = true;
        Object
            .keys(this.myRefs)
            .forEach(ref => {
                const enteredValue = this.myRefs[ref].value;
                const value = parseNumber(enteredValue);
                if (null === value) {
                    complete = false;
                    return;
                }

                result[ref] = blur || ref !== field ? formatNumberExceptNull(value) : enteredValue;
                dimensions *= value;
            });
        this.props.setValue({
            ...result,
            v:  complete ? formatNumberExceptNull(dimensions) : '',
            value: complete ? dimensions : null
        });
    }

    /**
     * Called when the "value" itself ist changed
     * @param e
     * @param blur
     */
    updateValue(e, blur = false) {
        const enteredValue = e.target.value;
        const value = parseNumber(enteredValue);

        const result = {
            v: blur ? formatNumberExceptNull(value) : enteredValue,
            value
        };
        // make dimensions
        this.getDimensions().map(d => result[d.key] = blur ? this.myRefs[d.key].value : '');
        this.props.setValue(result);
    }

    getValue(key) {
        const { value } = this.props;

        if (null === value) {
            return '';
        }

        return value[key];
    }

    render() {
        const { unit, placeholder } = this.props;
        const dimensions = this.getDimensions();
        return (
            <div className="row no-gutters">
                {dimensions.map(dimension => (
                    <div className="col" key={dimension.key}>
                        <input type="text"
                               key={dimension.key}
                               className={classNames('form-control text-sm-center', {'is-invalid': false})}
                               onChange={() => this.updateFragment(dimension.key, false)}
                               onBlur={() => this.updateFragment(dimension.key, true)}
                               ref={(input) => this.myRefs[dimension.key] = input}
                               aria-label={dimension.name}
                               placeholder={dimension.key}
                               value={this.getValue(dimension.key)}
                        />
                    </div>
                ))}
                {2 === dimensions.length && <div className="col"/>}
                <div className="col-4 pl-2">
                    <div className="input-group">
                        <input type="text"
                               className={classNames('form-control text-sm-center', {'is-invalid': false})}
                               onChange={(e) => this.updateValue(e, false)}
                               onBlur={(e) => this.updateValue(e, true)}
                               placeholder={placeholder}
                               value={this.getValue('v')}
                        />
                        <span className="input-group-addon">{unit}</span>
                    </div>
                </div>
            </div>
        );
    }
}
InputDimensions.defaultProps = {
    setValue: value => {}
};

export default InputDimensions;
