'use strict';

import { polyfill } from 'es6-promise';
polyfill();

import React from 'react';
import ReactDOM from 'react-dom';

import '../scss/main.scss';
import Application from './Application';

document.getElementById('app') && ReactDOM.render(<Application/>, document.getElementById('app'));
