'use strict';

import React, { Component } from 'react';
import classNames from 'classnames'

import {TYPE_EINFAMILIENHAUS, TYPE_MEHRFAMILENHAUS, TYPE_SCHEUNE, TYPE_HALLE, TYPE_SONSTIGES} from "./BuildingTypeSelection";
import InputDimensions, { InputSingleDimension } from "./../InputDimensions";
import {parseNumber, formatNumber} from "./../number"



const EMPTY_SCHADSTOFF = {
    buildingType: '',
    value: null
};

class CalculationForm extends Component
{
    isBuildingType(buildingType) {
        return this.props.calculation.buildingType === buildingType;
    }

    addSchadstoff() {
        const {schadstoffe} = this.props.calculation;
        const {updateSchadstoffe} = this.props;
        schadstoffe.push(EMPTY_SCHADSTOFF);
        updateSchadstoffe(schadstoffe);
    }

    setSchadstoff(i, value) {
        const {schadstoffe} = this.props.calculation;
        const {updateSchadstoffe} = this.props;
        schadstoffe[i] = value;
        updateSchadstoffe(schadstoffe);
    }

    removeSchadstoff(i) {
        const {schadstoffe} = this.props.calculation;
        const {updateSchadstoffe} = this.props;
        schadstoffe.splice(i, 1);
        updateSchadstoffe(schadstoffe);
    }

    render() {
        const { calculation, setCalculation, calculate } = this.props;

        const canCalculate = calculation.uR && calculation.uR.value > 0;

        return (
            <div>
                <div className="card card-body mb-2">
                    <TextWithDimensions3 setValue={(value) => setCalculation('uR', value)} value={calculation.uR} text="Bitte geben Sie die Dimensionen des Gebäudes ein" placeholder="uR" />
                    {this.isBuildingType(TYPE_SCHEUNE) && <TextWithDimensions3 setValue={(value) => setCalculation('stall', value)} value={calculation.stall} text="Ist ein Stall oder Keller vorhanden?" />}
                    {this.isBuildingType(TYPE_HALLE) && <section>
                        <TextWithDimensions2 setValue={(value) => setCalculation('dachBlech', value)} value={calculation.dachBlech} text="Dachdeckung Blech/Sandwichpaneele" />
                        <TextWithDimensions2 setValue={(value) => setCalculation('dachPappe', value)} value={calculation.dachPappe} text="Dachdeckung Dachpappe" />
                        <TextWithDimensions2 setValue={(value) => setCalculation('dachBims', value)} value={calculation.dachBims} text="Dachdeckung Bimsstegplatten" />
                        <TextWithDimensions2 setValue={(value) => setCalculation('wandBlech', value)} value={calculation.wandBlech} text="Wandaufbau Blech/Sandwichpaneele" />
                        <TextWithDimensions2 setValue={(value) => setCalculation('wandGlas', value)} value={calculation.wandGlas} text="Wandaufbau Porenbeton" />
                    </section>}

                    {0 === calculation.schadstoffe.length && <p className="mb-0 mt-2">Wenn in dem rückzubauenden Gebäude Schadstoffe wie Asbestzementplatten, teerhaltiger Parkett, Mineralwolle, Gussasphaltestrich oder Vollwärmeschutz an Aussenfassaden vorhanden sind, fügen Sie diese bitte durch Klick auf "Schadstoffe hinzufügen" der Berechnung hinzu.</p>}
                    {calculation.schadstoffe.map((schadstoff, i) => (
                        <SchadstoffRow
                            key={i}
                            onChange={(value) => this.setSchadstoff(i, value)}
                            onRemove={() => this.removeSchadstoff(i)}
                            value={schadstoff} />
                    ))}
                </div>

                <div className="text-right my-2">
                    <button className={classNames('btn btn-secondary', { disabled: false })} onClick={() => this.addSchadstoff()}>Schadstoffe hinzufügen</button>
                    {' '}
                    <button className={classNames('btn btn-primary', { disabled: !canCalculate })} onClick={() => canCalculate && calculate()}>Berechnen</button>
                </div>
            </div>
        );
    }
}
CalculationForm.defaultProps = {
    calculation: {},
    setCalculation: () => {},
    calculate: () => {},
    updateSchadstoffe: () => {},
    canCalculate: false
};

export default CalculationForm;




const TextWithDimensions2 = ({text, name, value, setValue, placeholder = ''}) => (
    <div className="form-group row">
        <label htmlFor={name} className="col-md-6 col-form-label">{text}</label>
        <div className="col-md-6">
            <InputDimensions dimensions={{"B": "Breite", "L": "Länge"}} unit={"m²"} value={value} setValue={setValue} placeholder={placeholder} />
        </div>
    </div>
);

const TextWithDimensions3 = ({text, name, value, setValue, placeholder}) => (
    <div className="form-group row">
        <label htmlFor={name} className="col-md-6 col-form-label">{text}</label>
        <div className="col-md-6">
            <InputDimensions dimensions={{"B": "Breite", "L": "Länge", "H": "Höhe"}} unit={"m³"} value={value} setValue={setValue} placeholder={placeholder} />
        </div>
    </div>
);

class SchadstoffRow extends Component
{
    setValue(value) {
        this.props.onChange({
            ...this.props.value,
            ...value
        });
    }

    render() {
        const { value, buildingType } = this.props.value;
        return (
            <div className="row mb-2">
                <div className="col-md-6">
                    <button type="button" className="close pull-right align-middle" aria-label="Close" onClick={() => this.props.onRemove()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <select className="form-control" value={buildingType} onChange={(e) => this.setValue({ buildingType:e.target.value })} style={{width: '90%'}}>
                        <option>Bitte wählen Sie einen Schadstoff</option>
                        <option value="Asbest">Asbestzementplatten</option>
                        <option value="Teer">teerhaltiges Parkett / Kleber</option>
                        <option value="Mineralwolle">Mineralwolle</option>
                        <option value="Gussasphaltestrich">Gussasphaltestrich</option>
                        <option value="Vollwaermeschutz">Vollwärmeschutz an Aussenfassade</option>
                    </select>
                </div>
                <div className="col-md-6">
                    <InputDimensions dimensions={{"B": "Breite", "L": "Länge"}} unit={"m²"} value={value} setValue={(value) => this.setValue({value})} />
                </div>
            </div>
        );
    }
}
SchadstoffRow.defaultProps = {
    onChange: () => {},
    onRemove: () => {}
};

const NumberFormat = ({value, unit}) => (
    value !== null && value.value !== null ? <nobr className="h4">{formatNumber(value.value)} {unit}</nobr> : <span className="h4 text-muted">k.A.</span>
);
