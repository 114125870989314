'use strict';

import React, { Component } from 'react';
import {formatPrice} from "../number";

class Price extends Component
{
    render() {
        const { price } = this.props;
        return (
            <div className="mb-5 text-center">
                <div className="h1 text-success">ca. {formatPrice(price)} netto zzgl. MwSt.</div>
                Müssen Sie für den Abriss Ihres Gebäudes kalkulieren.
                <div className="small mt-2">Hinweis: Bei der errechneten Kostenschätzung handelt es sich um einen Richtwert. Sollten Sie Interesse an einem unverbindlichen Angebot haben, sind Abweichungen möglich!</div>
            </div>
        );
    }
}

export default Price;
