'use strict';

import request from 'superagent'

/**
 * Checks zip
 *
 * @param zip
 * @param callback
 */
export const checkZip = (zip, callback = json => { }) => {
    request
        .post('/check-zip/')
        .field('zip', zip)
        .then(response => callback(response.body));
};


const appendCalculationToRequest = (object, calculation) => {
    const { zip, buildingType, uR, stall, dachBlech, dachPappe, dachBims, wandBlech, wandGlas, schadstoffe } = calculation;

    const fix = value => value && value.value !== null ? value.value : 0;

    const final = {
        Asbest: 0,
        Teer: 0,
        Mineralwolle: 0,
        Gussasphaltestrich: 0,
        Vollwaermeschutz: 0
    };
    schadstoffe.forEach(schadstoff => final[schadstoff.buildingType] += fix(schadstoff.value));

    return object
        .field('zip', zip)
        .field('buildingType', buildingType)
        .field('values[uR]', fix(uR))
        .field('values[stall]', fix(stall))
        .field('values[dachBlech]', fix(dachBlech))
        .field('values[dachPappe]', fix(dachPappe))
        .field('values[dachBims]', fix(dachBims))
        .field('values[wandBlech]', fix(wandBlech))
        .field('values[wandGlas]', fix(wandGlas))
        .field('schadstoffe[Asbest]', final.Asbest)
        .field('schadstoffe[Teer]', final.Teer)
        .field('schadstoffe[Mineralwolle]', final.Mineralwolle)
        .field('schadstoffe[Gussasphaltestrich]', final.Gussasphaltestrich)
        .field('schadstoffe[Vollwaermeschutz]', final.Vollwaermeschutz)
};

/**
 * Calculates price according to calculation
 *
 * @param calculation
 * @param callback
 */
export const calculate = (calculation, callback = json => { }) => {
    appendCalculationToRequest(request.post('/calculate/'), calculation)
        .then(response => callback(response.body));
};

/**
 * Sends contact form email according to calculation and
 *
 * @param calculation
 * @param contact
 * @param callback
 */
export const sendContactForm = (calculation, contact, files, callback = json => { }) => {
    const appendFiles = (request, files) => {
        files.forEach(file => request.attach('file[]', file.files[0]));
        return request;
    };

    appendFiles(appendCalculationToRequest(request.post('/contact/'), calculation)
        .field('contact[name]', contact.name)
        .field('contact[email]', contact.email)
        .field('contact[phone]', contact.phone)
        .field('contact[street]', contact.street)
        .field('contact[yesno]', contact.yesno ? 1 : 0)
        .field('contact[street2]', contact.street2)
        .field('contact[city2]', contact.city2)
        .field('contact[city]', contact.city), files)
        .then(response => callback(response.body));
};